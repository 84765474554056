.login-container {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: row;
  width: 100%;
  height: 100vh;
}

.login-left {
  display: flex;
  align-items: center;
  flex-direction: column;
  width: 35%;
  height: 50%;
  border-right: 1px solid;
}

.login-left h5 {
  margin: 20px 0;
  font-size: 14px;
}

.login-youtube-icon {
  width: 150px;
  height: 150px;
  background-size: cover;
  background-image: url('../../assets/images/youtube.png');
}

.login-video-placeholder {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 80%;
  height: 80%;
  background-color: #EEEEEE;
  cursor: pointer;
}

.login-right {
  display: flex;
  align-items: center;
  flex-direction: column;
  width: 35%;
}

.login-container header {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.login-steps-container {
  display: flex;
  flex-direction: column;
  margin: 20px 0 30px 0;
}

.login-container header h1 {
  font-size: 35px;
  font-weight: 900;
}

.login-steps-container h5 {
  margin: 0px 0 10px 0;
}

.login-steps-container ul {
  padding: 0;
  list-style: none;
}

.login-steps-container li {
  height: 30px;
}

.login-steps-container li span {
  font-weight: 800;
  margin: 0 5px 0 0;
}