.event {
  display: flex;
  justify-content: space-between;
}

.event-left {
  display: flex;
  width: 100%;
}

.event-title {
  display: flex;
  flex-direction: column;
  width: 35%;
  text-overflow: ellipsis;
  /* white-space: nowrap;
  overflow: hidden; */
}

.event-links {
  display: flex;
  flex-direction: column;
  width: 65%;
  margin: 0 0 0 20px;
  font-size: 10px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.event-links a {
  color: #FFF;
  cursor: pointer;
}

.event-links .heading {
  font-size: 13px;
  font-weight: 500;
}

.event-link {
  cursor: pointer;
}

.zoom-icon {
  min-width: 40px;
  height: 40px;
  margin: 15px 0px 0 15px;
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
  background-image: url('../../../../assets/images/zoom-icon.png');
  cursor: pointer;
}