.error-container {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  flex: 1;
  height: 100vh;
}

.error-container button {
  padding: 15px 25px;
  margin: 40px 0 0 0;
  border-radius: 3px;
  border: none;
  background-color: #2D9CDB;
  cursor: pointer;
  outline: none;
  color: #FFFFFF;
  font-size: 15px;
  font-family: sans-serif;
}