.side-panel-container {
  width: 350px;
  height: 100%;
  padding: 25px;
  background-color: #2C9CDB;
  box-sizing: border-box;
  z-index: 100;
}

.side-panel-container .sidebar-close {
  display: none;
  width: 20px;
  height: 20px;
  margin: 0 0 30px 0;
  background-size: contain;
  background-image: url('../../../../assets/images/x_icon_white.png');
}

.heading {
  color: #FFF;
  font-size: 20px;
  font-family: sans-serif;
  font-weight: 300;
}

.top-margin {
  margin: 50px 0 0 0;
}

.side-calendars-list {
  margin: 10px 0;
}

.side-calendars-list-item {
  display: flex;
  align-items: center;
  justify-content: space-between;
  position: relative;
  width: 100%;
  height: 40px;
}

.side-calendars-list-item>.left-side {
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  word-wrap: break-word;
  width: 80%;
}

.side-calendars-list-item>.left-side>.calendar-icon {
  width: 20px;
  height: 20px;
  margin: 0 10px 0 0;
  background-repeat: no-repeat;
  background-size: contain;
  background-position: center;
  background-image: url('../../../../assets/images/calendar-icon.svg');
  cursor: pointer;
}

.side-calendars-list-item>.left-side>.calendar-name {
  color: #FFF;
  font-size: 15px;
  font-family: sans-serif;
  font-weight: 500;
  cursor: pointer;
}

.side-calendars-list-item .action-button {
  min-width: 80px;
  height: 22px;
  border: none;
  border-radius: 100px;
  background-color: #FFFFFF;
  color: #2D9CDB;
  font-weight: 700;
  font-family: sans-serif;
  cursor: pointer;
  outline: none;
}

.side-calendars-list-item .tip-container {
  position: absolute;
  top: -70px;
  left: 380px;
  width: 300px;
  color: black;
}

.tip-heading {
  font-size: 25px;
  font-family: sans-serif;
  font-weight: 500;
  text-align: left;
}

.tip-content {
  margin: 15px 0;
  font-size: 15px;
  font-family: sans-serif;
  font-weight: 400;
  text-align: left;
  line-height: 20px;
}

.service-description {
  margin: 20px 0 0 0;
  color: #FFF;
  font-size: 15px;
  font-family: sans-serif;
  font-weight: 500;
  line-height: 20px;
  cursor: pointer;
}

.tip-arrow {
  position: absolute;
  top: calc(50% - 28px);
  left: -45px;
  width: 25px;
  height: 25px;
  background-size: contain;
  background-image: url('../../../../assets/images/tip-arrow.png');
}

.none-yet {
  margin: 20px 20px 0 20px;
  color: #FFF;
  font-size: 15px;
}

.side-calendars-list-item-icon {
  width: 20px;
  height: 20px;
  background-size: contain;
  cursor: pointer;
}

.side-calendars-list-item-icon.settings {
  background-image: url('../../../../assets/images/calendar-settings.png');
}

.side-calendars-list-item-icon.disconnect {
  background-image: url('../../../../assets/images/calendar-disconnect.png');
}

@media screen and (max-width: 900px) {
  .side-panel-container {
    position: absolute;
    left: -2000px;
  }
  .side-panel-container.opened {
    left: 0px;
  }
  .side-panel-container .sidebar-close {
    display: block;
  }
}