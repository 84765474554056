.fc-head {
  display: none;
}

.fc-toolbar {
  display: none;
}

.fc-day-header {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 50px;
  border-width: 0!important;
}

.fc-time-grid .fc-slats td {
  height: 3em;
}

.fc-now-indicator {
  border: 1px solid red;
}

.fc-now-indicator-line {
  left: -400px!important;
}