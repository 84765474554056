.settings-container {
  display: flex;
  /* align-items: center; */
  flex-direction: column;
  width: 100%;
  height: 90vh;
  padding: 20px 30px 0 30px;
  box-sizing: border-box;
}

.settings-container header {
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  height: 7vh;
  padding: 0 30px 0 30px;
  box-sizing: border-box;
  box-shadow: 0 10px 25px rgba(0, 0, 0, .15);
}

.settings-container header h2 {
  width: auto;
  font-size: 17px;
}

.settings-container header a {
  display: flex;
  align-items: center;
  height: 100%;
  margin: 0;
  border: none;
  background-color: #FFFFFF00;
  color: #000000;
  text-decoration: underline;
}

.settings-container h2 {
  width: auto;
  margin: 0;
  font-size: 25px;
  line-height: 20px;
}

.settings-content {
  height: inherit;
  padding: 20px 30px 30px 30px;
  background-color: #EEEEEE;
  border-radius: 10px;
}

.settings-content-top {
  display: flex;
  justify-content: space-between;
}

.settings-content h3 {
  margin: 0;
  font-size: 35px;
  font-weight: 300;
}

.service-block {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
}

.service-block button:hover {
  color: #FFFFFF;
  background-color: #2D9CDB;
}

.service-block input {
  cursor: auto;
}

.service-block>div {
  margin: 10px 0 20px 0;
}

.centered {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
}

.welcome {
  display: flex;
  align-items: center;
  flex-direction: column;
  margin: 40px 0;
}

h2 {
  width: 80%;
  text-align: center;
}

h5 {
  margin: 0 0 40px 0;
  font-weight: 500;
}

.authbutton {
  max-width: 420px;
  padding: 10px 45px 10px 45px;
  border: 1px solid #2D9CDB;
  background-color: #2D9CDB;
  border-radius: 5px;
  color: #FFFFFF;
  font-size: 18px;
  font-weight: 300;
  font-family: sans-serif;
  outline: none;
  cursor: pointer;
}

.authbutton.in {
  background-color: #FFFFFF;
  color: #2D9CDB;
}

.authbutton.out {
  background-color: #2D9CDB;
  color: #FFFFFF;
}

.calendars-list {
  display: flex;
  align-items: center;
  flex-direction: column;
}

.calendar-listitem {
  display: flex;
  align-items: center;
  justify-content: space-between;
  flex-direction: row;
  width: 100%;
  margin: 30px 0 0 0;
}

.calendar-content {
  display: flex;
  align-items: flex-start;
  justify-content: center;
  flex-direction: column;
  width: fit-content;
  max-width: 70vw;
  height: 100%;
  overflow: auto;
}

.calendar-content div {
  /* margin: 0 0 10px 0; */
}

.calendar-button {
  padding: 5px 20px;
  margin: 0 0 0 40px;
  background-color: #FFFFFF;
  border: 1px solid #2D9CDB;
  border-radius: 3px;
  color: #2D9CDB;
  font-size: 13px;
  outline: none;
  cursor: pointer;
}

.calendar-button:hover {
  color: #FFFFFF;
  background-color: #2D9CDB;
}

.invite-container {
  display: flex;
  align-items: flex-start;
  flex-direction: column;
}

.invite-copy {
  min-width: 170px;
  padding: 12px 20px;
  margin: 40px 0 0 0;
  background-color: #FFFFFF;
  border: 1px solid #2D9CDB;
  border-radius: 3px;
  color: #2D9CDB;
  font-size: 13px;
  font-weight: 500;
  outline: none;
  cursor: pointer;
}

.invite-copy:hover {
  color: #FFFFFF;
  background-color: #2D9CDB;
}

.invite-link {
  width: 80%;
  word-break: break-all;
  text-align: center;
  margin: 10px 0 0 0;
  color: #2D9CDB;
  font-size: 20px;
}

.a:visited {
  color: #2D9CDB;
}

.a:hover {
  color: red;
}

.invite-back {
  min-width: 170px;
  padding: 12px 20px;
  margin: 20px 0 0 0;
  background-color: #FFFFFF;
  border: 1px solid #2D9CDB;
  border-radius: 3px;
  color: #2D9CDB;
  font-size: 13px;
  font-weight: 500;
  outline: none;
  cursor: pointer;
}

.pin-input-container {
  display: flex;
  margin: 20px 0 0 0;
}

.pin-checkbox-container {
  display: flex;
  margin: 20px 0 0 0;
}

.set-url-input {
  display: flex;
  flex-grow: 1;
  min-width: 400px;
  padding: 12px 20px;
  background-color: #FFFFFF;
  border: 1px solid #000000;
  border-radius: 3px;
  color: #000000;
  font-size: 15px;
  font-weight: 500;
  outline: none;
  cursor: pointer;
}

.apply-button {
  width: 100px;
  margin: 0 0 0 10px!important;
  padding: 0 20px;
  border: 1px solid #2D9CDB;
  border-radius: 3px;
  background-color: #2D9CDB!important;
  cursor: pointer;
  outline: none;
  color: #FFF;
  font-size: 12px;
}

.apply-button.loading {
  border: 1px solid #CCC;
  background-color: #CCC!important;
}

.pin-heading {
  width: 90px;
  margin: auto 10px auto auto!important;
}

.pin-error {
  display: flex;
  justify-content: center;
  margin: 10px 0 0 0;
  color: red;
}

.url-heading {
  width: 90px;
  margin: auto 10px auto auto!important;
}

.set-pin-input {
  display: flex;
  width: 150px;
  padding: 12px 20px;
  background-color: #FFFFFF;
  border: 1px solid #000000;
  border-radius: 3px;
  color: #000000;
  font-size: 15px;
  font-weight: 500;
  outline: none;
  cursor: pointer;
}

.calendar-action-container {
  display: flex;
  justify-content: flex-end;
  margin: 60px 0 0 0;
}

.connect-button {
  margin: 0 10px 0 0;
  padding: 20px 35px;
  border: 1px solid #2D9CDB;
  border-radius: 3px;
  background-color: #2D9CDB!important;
  cursor: pointer;
  outline: none;
  color: #FFF;
  font-size: 20px;
}

.update-button {
  margin: 0 10px 0 0;
  padding: 20px 35px;
  border: 1px solid #19B100;
  border-radius: 3px;
  background-color: #19B100!important;
  cursor: pointer;
  outline: none;
  color: #FFF;
  font-size: 20px;
}

.disconnect-button {
  margin: 0 10px 0 0;
  padding: 20px 35px;
  border: 1px solid #FF0B0B;
  border-radius: 3px;
  background-color: #FF0B0B!important;
  cursor: pointer;
  outline: none;
  color: #FFF;
  font-size: 20px;
}

.cancel-button {
  padding: 15px 40px;
  border: 1px solid #2D9CDB;
  border-radius: 3px;
  background-color: #FFFFFF00;
  cursor: pointer;
  outline: none;
  color: #2D9CDB;
  font-size: 15px;
}

.upload-container.mobile {
  display: none;
}

.upload-container.desktop {
  display: flex;
}

.upload-container {
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  width: 250px;
  height: 300px;
  border: 2px dashed #808080;
  border-radius: 20px;
  background-color: #FFF;
  box-sizing: border-box;
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;
}

.upload-container h4 {
  margin: 0;
  font-size: 12px;
}

.upload-container button {
  position: absolute;
  bottom: 20px;
  width: 200px;
  height: 40px;
  border: none;
  border-radius: 5px;
  background-color: #2C9CDB;
  color: #FFF;
  font-size: 15px;
  outline: none;
  cursor: pointer;
}

.upload-container button>input[type='file'] {
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  opacity: 0;
  cursor: pointer;
  outline: none;
}

.upload-error {
  display: flex;
  justify-content: center;
  width: 100%;
  margin-top: 10px;
  color: red;
  font-size: 15px;
}

.file-drop {
  position: relative;
  width: 100%;
  height: 100%;
  border-radius: 20px;
  box-sizing: border-box;
}

.file-drop>.file-drop-target {
  /* basic styles */
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
  padding: 0 60px;
  line-height: 30px;
  /* horizontally and vertically center all content */
  display: flex;
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  flex-direction: column;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -webkit-flex-direction: column;
  -ms-flex-direction: column;
  align-items: center;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  justify-content: center;
  -webkit-box-pack: center;
  -webkit-justify-content: center;
  -ms-flex-pack: center;
  align-content: center;
  -webkit-align-content: center;
  -ms-flex-line-pack: center;
  text-align: center;
  border-radius: 20px;
  box-sizing: border-box;
}

.file-drop>.file-drop-target.file-drop-dragging-over-frame {
  /* overlay a black mask when dragging over the frame */
  border: none;
  background-color: rgba(0, 0, 0, 0.25);
  box-shadow: none;
  z-index: 50;
  opacity: 1;
  /* typography */
  color: white;
}

.file-drop>.file-drop-target.file-drop-dragging-over-target {
  /* turn stuff orange when we are dragging over the target */
  color: #2C9CDB;
}

.connected-clients-container {
  margin: 30px 0 0 0;
  height: 300px;
  overflow-y: auto;
}

.connected-clients-container table {
  width: 100%;
  margin: 25px 0;
}

.connected-clients-container th, td {
  width: 25%;
  text-align: left;
}

.connected-clients-container td {
  padding: 25px 0 5px 0;
}

.pin-checkbox-heading {
  width: auto;
  margin: 0 0 0 10px;
}

.action-buttons-container {
  display: flex;
  justify-content: space-between;
}

.action-buttons-note {
  display: flex;
  width: 40%;
  line-height: 25px;
}

.action-buttons-note>span {
  margin: 0 10px 0 0;
  font-weight: 600;
}

.action-buttons {
  display: flex;
}

@media screen and (max-width: 900px) {
  .settings-container {
    padding: 20px 20px 0 20px;
  }
  .calendar-listitem {
    width: 80%;
    margin: 20px 0 0 0;
  }
  .calendar-content {
    width: 90%;
    padding: 10px 0;
  }
  .invite-heading {
    width: 70%;
    text-align: center;
  }
  .invite-link {
    font-size: 18px;
  }
  .invite-copy:hover {
    color: #2D9CDB;
    background-color: #FFFFFF;
  }
  .service-block {
    width: 100%;
    display: flex;
    align-items: center;
    flex-direction: column;
  }
  .invite-container {
    width: 100%;
  }
  .pin-input-container {
    display: flex;
    flex-direction: column;
    width: 100%;
  }
  .pin-сheckbox-container {
    flex-direction: row;
  }
  .url-heading {
    margin: 10px 0!important;
  }
  .upload-container.desktop {
    display: none;
  }
  .upload-container.mobile {
    display: flex;
  }
  .apply-button {
    margin: 10px 0 0 0!important;
    height: 40px;
  }
  .set-url-input {
    min-width: 80%;
  }
  .service-block {
    padding: 15px 20px;
  }
  .service-block h3 {
    font-size: 25px;
  }
  .pin-heading {
    margin: 10px 0!important;
  }
  .connect-button {
    margin: 0;
    font-size: 13px;
  }
  .settings-content {
    padding: 0 0 60px 0;
  }
  .action-buttons-note {
    display: none;
  }
  .calendar-action-container {
    flex-direction: column;
    margin: 35px 0 30px 0;
  }
  .connected-clients-container th, td {
    font-size: 11px;
  }
  .update-button {
    height: 75px;
    padding: 15px 20px;
    font-size: 12px;
  }
  .disconnect-button {
    height: 75px;
    padding: 15px 20px;
    margin: 0;
    font-size: 12px;
  }
  .cancel-button {
    height: 75px;
    padding: 15px 20px;
    margin: 10px 0 0 0;
    font-size: 12px;
  }
}