.container {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  height: 100vh;
}

.installation-container {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  height: 100vh;
}

.installation-container p {
  margin: 10px 0;
}

.open-button {
  min-width: 170px;
  padding: 12px 20px;
  margin: 30px 0 0 0;
  background-color: #2D9CDB;
  border-radius: 3px;
  color: #FFFFFF;
  font-size: 13px;
  font-weight: 500;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;
  outline: none;
  cursor: pointer;
}

.submit-pin-input {
  width: 250px;
  padding: 8px 10px;
  font-size: 15px;
  text-align: center;
  outline: none;
}

.submit-pin {
  width: 140px;
  padding: 15px 25px;
  margin: 20px 0;
  background-color: #FFFFFF;
  border: 1px solid;
  border-radius: 3px;
  font-size: 12px;
  outline: none;
}

.submit-pin:hover {
  color: #FFF;
  background-color: #2D9CDB;
  cursor: pointer;
}

.pin-error {
  color: red;
}

.check-centered {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 100vh;
}

@media screen and (max-width: 900px) {}