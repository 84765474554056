.dashboard-container {
  display: flex;
  align-items: center;
  flex-direction: column;
  flex-grow: 1;
  /* height: 100vh; */
  /* overflow: hidden; */
}

.current-line {
  top: 0!important;
  width: 3px;
  background: #FF0B0B!important;
  border: none!important;
}

.right-panel {
  display: flex;
  align-items: center;
  height: 100%;
  padding: 0 20px;
}

.user-icon {
  width: 30px;
  height: 30px;
  margin: 0 0 0 20px;
  border-radius: 100px;
  background-position: center;
  background-color: #CCC;
  background-size: contain;
  cursor: pointer;
}

.user-name {
  cursor: pointer;
}

.events-calendar-legend {
  display: flex;
}

.dashboard-content {
  display: flex;
  width: 100%;
  height: 100%;
}

.dashboard-events-list {
  width: 100%;
  height: 100%;
  padding: 50px;
  box-sizing: border-box;
  overflow: auto;
}

.dashboard-events-list .events-heading {
  margin: 0 0 20px 0;
  font-size: 30px;
}

.dashboard-heading-container {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}

.events-view-buttons-container {
  display: flex;
  flex-direction: row;
}

.events-view-button {
  width: 100px;
  height: 40px;
  padding: 5px 20px;
  margin: 0 20px 0 0;
  border: 1px solid #2C9CDB;
  outline: none;
  cursor: pointer;
  border-radius: 3px;
  color: #2C9CDB;
  font-size: 15px;
}

.events-view-button:first-of-type {
  margin: 0 5px 0 0;
}

.events-view-button.active {
  color: #FFFFFF;
  background-color: #2C9CDB;
}

.dashboard-events-list .events-calendar {
  margin: 0;
  color: #6BB9E5;
  font-size: 25px;
}

.dashboard-events-list>.list {
  display: flex;
  flex-direction: row;
  position: relative;
  width: 70vw;
  margin: 20px 0 0 0;
}

.dashboard-events-list>.list>div {
  margin: 0 20px 0 0;
}

.dashboard-events-list-item {
  display: flex;
  width: 100%;
  height: 4.16%;
}

.dashboard-events-event {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  background-color: #F3AD4333;
  border: 1px solid #F3AD43;
}

.dashboard-events-event>.links {
  display: flex;
  flex-direction: column;
}

.dashboard-events-event>.links>.link {
  text-decoration: underline;
  cursor: pointer;
}

.dashboard-events-list-item .line {
  width: 100%;
  margin: 10px 0 0 0;
  border-top: 2px dashed #A1D2EE;
}

.dashboard-events-list-item .line.solid {
  border-top: 2px solid #D9F3FC;
}

.dashboard-new-event {
  display: flex;
  width: 50%;
  height: 88%;
  padding: 20px;
  background: red;
  border-radius: 20px;
  background-color: #E5E5E5;
  box-sizing: border-box;
}

.dashboard-new-event p {
  margin: 0;
  font-size: 18px;
  font-weight: 700;
  font-family: sans-serif;
}

.link-modal-wrapper {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: #00000033;
}

.link-modal {
  position: absolute;
  top: 30%;
  left: 20%;
  width: 60%;
  padding: 6%;
  border: 1px solid;
  background-color: #FFF;
  box-sizing: border-box;
}

.link-modal>div {
  margin: 20px 0;
}

.link-modal>input {
  padding: 10px 10px;
  font-family: sans-serif;
  font-size: 15px;
  outline: none;
}

.link-modal>button {
  width: 100px;
  height: 30px;
  margin: 20px 0;
  background-color: #000000;
  cursor: pointer;
  border: none;
  color: #FFFFFF;
  outline: none;
}

.link-modal>button:first-of-type {
  margin: 20px 10px 20px 0;
}

.close-button {
  display: flex;
  align-items: center;
  justify-content: center;
  position: absolute;
  top: 0px;
  right: 15px;
  width: 25px;
  height: 25px;
  cursor: pointer;
}

.close {
  height: 18px;
  width: 2px;
  background-color: black;
  transform: rotate(45deg);
  z-index: 1;
}

.close-inner {
  height: 18px;
  width: 2px;
  background-color: black;
  transform: rotate(90deg);
  z-index: 2;
}

.event-container {
  font-size: 12px;
}

.event-history {
  display: flex;
  flex-direction: row;
}

.events-calendar-container {
  display: flex;
  flex-direction: row;
  align-items: center;
  margin: 10px 20px 20px 0;
}

.events-calendar-icon {
  min-width: 40px;
  max-width: 40px;
  width: 40px;
  height: 40px;
  margin: 0 15px 0 0;
  background-position: center;
  background-color: #CCC;
  background-size: contain;
  border-radius: 100px;
}

.events-calendar-name {
  font-family: sans-serif;
}

.events-calendar-color-example {
  width: 20px;
  height: 5px;
  margin: 0 0 0 15px;
  border-radius: 20px;
}

@media screen and (max-width: 900px) {
  .dashboard-heading-container {
    flex-direction: column;
  }
  .dashboard-events-list {
    padding: 25px;
  }
  .dashboard-events-list>.list {
    width: 1000px;
  }
  .events-view-button {
    background-color: #FFFFFF;
  }
}